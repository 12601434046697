import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept-Encoding': 'application/x-www-form-urlencoded',
};

const CallbackPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code');

        const parameters = new URLSearchParams({
            client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
            client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
            grant_type: 'authorization_code',
            code,
            redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI
        });

        if (code) {
            axios.post('https://discord.com/api/oauth2/token', parameters, { headers }).then((response) => {
                    localStorage.setItem('accessToken', response.data.access_token);
                    navigate('/dashboard');
                }).catch((error) => {
                    console.error(error);
                });
        }
    });

    return null;
};

export default CallbackPage;