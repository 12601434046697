import React from 'react';

const LoginPage = () => {
    const oauthUrl = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DISCORD_REDIRECT_URI}&response_type=code&scope=identify+guilds+guilds.members.read`;

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        window.location.href = '/dashboard';
    }

    return (
        <div>
            <a href={oauthUrl}>Login with Discord</a>
        </div>
    );
};

export default LoginPage;