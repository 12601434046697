import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { connect } from "./connect.js";

const DashboardPage = () => {
    const [userData, setUserData] = useState({
        id: null,
        username: null,
        avatar: null,
    });

    const [userGuildData, setUserGuildData] = useState({
        id: null,
        name: null,
        icon: null,
        permissions: [],
        roles: [],
    });

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) window.location.href = '/';

    useEffect(() => {
        if (accessToken) {
            axios.get('https://discord.com/api/users/@me', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }).then((response) => {
                    setUserData(response.data);
                }).catch((error) => {
                    console.error(error);
                    localStorage.removeItem('accessToken');
                });
        }
    }, [accessToken]);

    useEffect(() => {
       if (accessToken && userData.id) {
           axios.get(`https://yrrahbot.yrrah.live/api/users/?id=${userData.id}`).then((response) => {
              console.log(response);
              setUserGuildData(response.data);
          }).catch((error) => {
              console.error(error);
           })
       }
    }, [accessToken, userData]);

    return (
        userData.id ? (
            <div>
                <h1>Welcome, {userData.username}!</h1>
                { userData.id && userGuildData.id ? (
                    userData.id !== userGuildData.id ? (
                        <h2>There was an error loading your information. Please try again later.</h2>
                    ) : (
                        // See if the userData.permissions has the "Administrator" permission
                        userGuildData.permissions.includes('Administrator') ? (
                            // Connect to the mongodb
                            // connect().then(async (client) => {
                            //     // Create a list of the collections from [giflimiters, autoroles, stickyroles, no-streams, y-command-bans, y-sticky-roles] and make sure to only search for Yrrah's Server ID
                            //     const collections = ['giflimiters', 'autoroles', 'stickyroles', 'no-streams', 'y-command-bans', 'y-sticky-roles'];
                            //     for (const collection of collections) {
                            //         const db = client.db('test');
                            //         const collectionData = db.collection(collection);
                            //         const data = await collectionData.findOne({ guildID: process.env.REACT_APP_YRRAH_SERVER_ID });
                            //         console.log(data);
                            //     }
                                <h2>Admin!!!</h2>
                            // }).catch((error) => {
                            //     console.error(error);
                            // })
                        ) : (
                            <h2>You do not have the Administrator permission.</h2>
                        )
                    )
                ) : null }
                <h2>Yrrah's Place Information</h2>
                <ul>
                    {userGuildData.id ? (
                        <li>
                            <h3>Your current name: {userGuildData.name}</h3>
                            <img src={userGuildData.icon} alt="Guild Icon" />
                            <h4>Your Roles:</h4>
                            <ul>
                            {userGuildData.roles.sort((a, b) => b.rawPosition - a.rawPosition).map((role) => (
                                <li key={role.id}><h5>{role.name}</h5></li>
                            ))}
                            </ul>
                        </li>
                    ) : (
                        <li>
                            <h3>No Information Found</h3>
                        </li>
                    )}
                </ul>
            </div>
        ) : (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    );
};

export default DashboardPage;