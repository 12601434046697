import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import CallbackPage from './CallbackPage';
import DashboardPage from './DashboardPage';
import LogoutPage from "./LogoutPage";

function App() {
  return (
      <Router>
        <Routes>
            <Route path={"/"} element={<LoginPage />} />
            <Route path={"/auth/callback"} element={<CallbackPage />} />
            <Route path={"/dashboard"} element={<DashboardPage />} />
            <Route path={"/logout"} element={<LogoutPage />} />
        </Routes>
      </Router>
  );
}

export default App;